import React from "react";
import Alert from "react-bootstrap/Alert";
import { Chart } from "react-charts";

function PingResult({ responseArray, count }) {
  const axes = React.useMemo(
    () => [
      { primary: true, type: "linear", position: "bottom" },
      { type: "linear", position: "left" },
    ],
    []
  );

  if (!responseArray || !responseArray.length) return null;

  const data = [
    {
      label: "Series 1",
      data: generateData(responseArray),
    },
  ];

  const avg = calcAvg();

  function generateData(data) {
    return data.map((time, i) => {
      return { x: i, y: time };
    });
  }

  function calcAvg() {
    let sum = 0;
    responseArray.forEach((element) => {
      sum += element;
    });
    const avg = sum / responseArray.length;
    return Math.round(avg * 100) / 100;
  }

  return (
    <div>
      <Alert variant="primary">
        <h1>Average: {avg} ms</h1>
      </Alert>
      <div
        style={{
          height: "300px",
        }}
      >
        <Chart data={data} axes={axes} />
      </div>
      <div className="row">
        {responseArray.map((time, i) => (
          <div className="col">{time}ms</div>
        ))}
      </div>
    </div>
  );
}

export default PingResult;
