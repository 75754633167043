import React from 'react';
import Ping from './Ping';

function Homepage() {

    return <div>
        <h1>Average Response Time</h1>
        <Ping />
    </div>
}

export default React.memo(Homepage);