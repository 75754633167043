import axios from "axios";

export default class AxiosUtils {

  static async getMillis(url) {
    const headers = {
        'Content-Type': 'application/json'
    };
    return axios.get(url, headers).then(x => x.responseTime);
  }

  static async delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

}
