import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AxiosUtils from "../utils/AxiosUtils";
import PingResult from "./PingResult";

function Ping() {
  const [url, setUrl] = useState('https://va.play29.com');
  const [count, setCount] = useState(5);
  const [delay, setDelay] = useState(200);
  const [responses, setResponses] = useState([]);

  function handleSubmit(e) {
    e.preventDefault();
    setResponses([]);
    getMillis(url)
  }

  async function getMillis(url) {
    for (let i = 0; i < count; i++) {
        const adjust = 2;
        const millis = await AxiosUtils.getMillis(url) - adjust;
        setResponses(responses => [...responses, millis])
        await AxiosUtils.delay(delay);
    }
  }

  return (
    <div className="container">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="url">
          <Form.Label>URL</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <Form.Text className="text-muted">
            This URL's response time will be measured
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="delay">
          <Form.Label>Delay</Form.Label>
          <Form.Control
            type="number"
            placeholder="milliseconds"
            value={delay}
            onChange={(e) => setDelay(e.target.value)}
          />
          <Form.Text className="text-muted">
            Enter how many milliseconds between each request
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="count">
          <Form.Label>Count</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter number of times"
            value={count}
            onChange={(e) => setCount(e.target.value)}
          />
          <Form.Text className="text-muted">
            Enter how many times to hit the above URL
          </Form.Text>
        </Form.Group>

        <hr />
        <Button variant="primary" type="submit" >
          Submit
        </Button>
      </Form>
      <PingResult responseArray={responses} count={count} />
    </div>
  );
}

export default React.memo(Ping);
